#home-page{
    -webkit-overflow-scrolling: touch ;
    overscroll-behavior: touch;
    position: fixed ;
}
.bg-homepage {
  background-image: url("../../assets/images/bg-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch ;


}
.bg-hero {
  background-image: url("../../assets/images/bg-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-toxic {
  background-image: url("../../assets/images/toxic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-vision {
  background-image: url("../../assets/images/vision.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-white-polygon-pol {
  background-image: url("../../assets/images/polygon/white-polygon-pol-down.png"),
    url("../../assets/images/polygon/white-polygon-pol.png");
  background-repeat: no-repeat;
  background-position: left top, bottom right;
  background-size: 170px 90px, 170px 90px;
}
@media screen and (min-width: 768px) {
  .bg-polygon-present {
    background-image: url("../../assets/images/red-polygon-dot.png"),
      url("../../assets/images/polygon/red-polygon-dot-2.png");
    background-repeat: no-repeat;
    background-position: left top, bottom right;
    background-size: 90px 170px, 90px 170px;
  }
}

#card .swiper {
  /* min-height: 500px; */
  position: relative;
  width: 400px;
  padding: 2rem 3rem;
}
#card .swiper-button-next {
  color: #c3380b;
  width: 50px;
  height: 50px;
}

#card .swiper-button-prev {
  color: #c3380b;
}
#card .swiper-pagination-bullet {
  background-color: white;
}
#card .swiper-pagination-bullet-active {
  background-color: #c3380b;
}

#team .swiper {
  width: 290px;
  margin: 0;
  padding: 2rem 3rem;
}

@media screen and (max-width: 400px) {
  #card .swiper {
    width: 320px;
    padding: 3rem 1rem;
  margin: 0;

  }
  #card .swiper .swiper-pagination {
    top: 380px;
  }
  #card .swiper .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #c3380b;
  }
}

@media screen and (min-width:768px) {
    #team .swiper {
        width: 700px;
        margin: 0;
        padding: 2rem 1rem;
      }
      
}