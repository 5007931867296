@media screen and (min-width:768px) {
    .bg-contact{
        background-image: url('../../assets/images/polygon/double-polygon-fill.png'),url('../../assets/images/polygon-footer.png'),url('../../assets/images/polygon/red-polygon-fill-left.png'),url('../../assets/images/polygon/black-polygon-dot-left.png');
        background-repeat: no-repeat;
        background-position: left 15%,left 90%, right 80%, right 10%;
        background-size: 200px 400px,80px 140px,135px 242px,185px 325px;
    }
}
.bg-contact{
    background-image: url('../../assets/images/polygon/double-polygon-fill.png'),url('../../assets/images/polygon-footer.png'),url('../../assets/images/polygon/red-polygon-fill-left.png'),url('../../assets/images/polygon/black-polygon-dot-left.png');
    background-repeat: no-repeat;
    background-position: left 15%,left 90%, right 80%, right 10%;
    background-size: 100px 300px,80px 140px,65px 172px,115px 255px;
}